import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(
  ({breakpoints, shadow, layout, palette, fontSizes, spacing}) => ({
    wrapper: {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 100,
      borderRadius: 4,
      [breakpoints.down('xs')]: {
        width: '100%',
      },
      background: 'rgba(50, 50, 50, 0.5)',
      width: '100vw',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      width: 'auto',
      maxWidth: 600,
      maxHeight: 600,
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'lightgray',
        borderRadius: '10px',
      },
      minHeight: layout.popper.height,
      backgroundColor: palette.background.default,
      zIndex: 100,
      boxShadow: shadow.large,
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.down('md')]: {
        overflow: 'auto',
      },
      [breakpoints.down('xs')]: {
        width: '100%',
      },
      [breakpoints.down('sm')]: {
        maxHeight: 500,
        width: '95%',
      },
      padding: spacing(4),
    },
    closeButton: {
      position: 'relative',
      borderRadius: 16,
      width: 32,
      height: 32,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'white',
      color: 'white',
      left: '99%',

      transition: 'background 167ms cubic-bezier(0.33, 0, 0, 1) 0s',

      '&:hover': {
        background: palette.background.greyLight,
      },
      top: -28,
      border: 'none',

      [breakpoints.down('sm')]: {
        top: -25,
        left: '99%',
      },
    },
    closeButtonIcon: {
      width: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      color: 'black',
    },
  }),
)

export default useStyles
